/* eslint-disable */
import React from 'react'
import Helmet from '../components/Helmet'

import { graphql } from 'gatsby'

import { VariableContent, Typography, Tabs } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import OneTrustPrivacyPolicy from '../components/OneTrustPrivacyPolicy'

const PrivacyPolicy = ({ data }) => {
  const brandyList = ['disclaimer-hcp-tpmo']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: '',
          description: '',
          canonical: 'https://clearlink.com/',
          robots: 'follow,index',
        },
        path: '/terms',
        promoCode: '88487',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com',
          siteName: 'healthcareplans.com',
          alternateName: 'healthcareplans.com',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'healthcareplans.com | Privacy Policy',
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <VariableContent
          backgroundColor="primary"
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
          alignMainContent="center"
        >
          <OneTrustPrivacyPolicy />
        </VariableContent>
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default PrivacyPolicy

export const query = graphql`
  query PrivacyPolicyQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
